<template>
  <b-card no-body>

      <b-col md="6" sm="8" offset-md="6" class="float-right pt-2">
        <b-form-group
          label-cols-sm="3"
          label-align-sm="right"
          label-size="lg"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Procurar"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>


    <b-col md="">
      <div v-for="(item, index) in items" :key="index">
        <h4 class="ml-1 text-primary">{{item.nome}}</h4>
         <b-card no-body class="border">
            <b-table
              fixed
              v-if="item.servicos"
              class="table"
              hover
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :items="item.servicos"
              :fields="servicoFields"
            />
            <b-table
              fixed
              v-if="item.cat_produtos"
              class="table"
              hover
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :items="item.cat_produtos"
              :fields="produtosFields"
            />
            <b-table
              fixed
              v-if="item.cat_entregas"
              class="table"
              hover
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :items="item.cat_entregas"
              :fields="entregasFields"
            />
          </b-card>
      </div>
    </b-col>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BTable,
  BTableLite,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BButton,
  BFormGroup,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  name: "TabelaPrincipal",
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BTable,
    BTableLite,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BListGroup,
    BListGroupItem,
  },

  data() {
    return {
      servicoFields: [
        {
          key: "servico_nome",
          label: "",
          thStyle: { display: 'none' },
          sortable: true,
        },
        {
          key: "valor",
          label: "",
          thStyle: { display: "none" },
          sortable: true,
        },
      ],
      produtosFields: [
        {
          key: "produto.nome",
          label: "",
          thStyle: { display: 'none' },
          sortable: true,
        },
        {
          key: "valor",
          label: "",
          thStyle: { display: "none" },
          sortable: true,
        },
      ],
      entregasFields: [
        {
          key: "nome",
          label: "",
          thStyle: { display: 'none' },
          sortable: true,
        },
        {
          key: "valor",
          label: "",
          thStyle: { display: "none" },
          sortable: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
    };
  },

  created() {

    if(this.laboSelecionado) {
      this.$store.dispatch('tabelas/listaTabelas', this.laboSelecionado)
    }
    this.totalRows = this.items.length;
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    ...mapState({
      laboSelecionado: (state) => state.laboratorios.laboSelecionado,
      items: state => state.tabelas.tabelas
    }),
  },
  watch: {
    laboSelecionado(newValue, oldvalue) {
      this.$store.dispatch('tabelas/listaTabelas', this.laboSelecionado)
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
}

</style>
